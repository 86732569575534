import router from '../router'

import axios from 'axios';
let _api = 'https://onlinemall.bbjt.cc';
// let _api = 'http://192.168.1.35:5001';
let serviceType = null
if (_api === 'https://onlinemall.bbjt.cc') {
  serviceType = 1
} else if (_api === 'https://mallen.bbjt88.com') {
  serviceType = 2
} else if (_api === 'https://mallcn.bbjt.cc') {
  serviceType = 3
} else if (_api === 'https://mall.bbjt.cc') {
  serviceType = 4
}
localStorage.setItem("serviceType", serviceType);

const service = axios.create({
  baseURL: '', // api的base_url
  timeout: 10000, // 请求超时时间
  crossDomain: true,
  // withCredentials: true, // Check cross-site Access-Control
  // 定义可获得的http响应状态码
  // return true、设置为null或者undefined，promise将resolved,否则将rejected
  validateStatus (status) {
    return status >= 200 && status < 510
  },
})
service.interceptors.request.use(originConfig => {
  originConfig.url = originConfig.url.replace(/^\/_api\/(.+)/, _api + '$1');
  const token = localStorage.getItem('token')
  if (token) {
    originConfig.headers['Authorization'] = 'Bearer ' + token;
  }
  return originConfig
}, error => {
  Promise.reject(error)
})

service.interceptors.response.use(res => {
  let data = res;
  let req = res.config.data || res.config.params;
  if (data.code === 200) {
    return Promise.resolve(data.data ? data.data : res);
  } else if (data.code && !req.noJump) {
    if (data.code === 1011002) {
      Modal.error({
        title: '提示：',
        content: data.message,
        okText: '确定',
        onOk: () => {
        }
      })
    }
    return Promise.reject()
  }
  return Promise.resolve(data.data ? data.data : res);
}, error => {
  return Promise.reject(error)
})

let obj = {
  post (url, data) {
    return service({
      method: 'post',
      url,
      data: data
    })
  },
  get (url, data) {
    return service({
      method: 'get',
      url,
      params: data
    })
  },
  delete (url, data) {
    return service({
      methods: 'delete',
      url,
      params: data
    })
  }
}

export default obj;
