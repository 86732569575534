let homeSwiper = 8
let companyInfo = 122
//首页轮播图
let companyProfile = 8
//公司简介
let platform = 10
//公司简介
let solution = 12
//解决方案
let solutionList = 94
//解决方案
let advertising = 15
//广告轮播图
let partners = 19
//合作伙伴
let inbanner = 26  //置顶关于我们
let solutionbannerList = 11 //解决方案轮播图
let caseBannerList = 118 //项目案例轮播图
let dynamicBannerList = 112 //佳智慧资讯轮播图
let serveBannerList = 119 //服务支持轮播图
let agentBannerList = 120 //招商代理轮播图
let aboutBannerList = 21 //关于我们轮播图
let introduction = 17  //简介
let qualification = 23  //资质
let base = 24  //生产基地
let contact = 25 //联系
let cases = 27 //案例
let casesImg = 28 //联系
let fireTop = 41 //消防顶部
let fireForm = 42 //消防形式
let FireProblem = 43 //消防难题
let technology = 44 //技术优势
let solutions = 11 //解决方案
let trait = 46 //特点
let productTop = 15 //产品中心置顶
let productTop1 = 132 //产品中心置顶
let productTop2 = 133 //产品中心置顶
let productTop3 = 134 //产品中心置顶
let productTop4 = 135 //产品中心置顶
let productTop5 = 136 //产品中心置顶
let productTop6 = 137 //产品中心置顶
let productTop7 = 138 //产品中心置顶
let productTop8 = 139 //产品中心置顶
let productTop9 = 144 //产品中心置顶
// let classifyImg = 60 //产品分类灰色图标
let classifyImgh = 65 //产品分类红色图标
let fireProducts = 61 //消防产品
let agedProducts = 62 //智慧养老
let helmetProducts = 63 //智能安全帽
let baseProducts = 64 //定位基站
let planTop = 75 //解决方案顶部图片
let blogroll = 72 //友情链接
let information = 78//佳智慧资讯置顶
let forumTop = 81//行业论坛置顶
let supplier = 204//供应商留言
let customer = 83//客户留言
let agentarticle = 205//招商代理文章
export default {

  companyInfo: `/_api//v1/banner/${companyInfo}`,
  registered: `/_api//v1/reg/add`,
  login: `/_api//v1/login`,
  verificationCode: `/_api//v1/reg/sms`,
  homeSwiper: `/_api//v1/banner/${homeSwiper}/list`,
  companyProfile: `/_api//v1/banner/${companyProfile}`,
  gridCategory: '/_api//v1/grid-category/list',
  platform: `/_api//v1/banner/${platform}`,
  solution: `/_api//v1/banner/${solution}/list`,
  solutionList: `/_api//v1/banner/${solutionList}`,
  // 解决方案bannerList
  solutionBannerList: `/_api//v1/banner/${solutionbannerList}`,
  // 项目案例bannerList
  caseSwiper: `/_api//v1/banner/${caseBannerList}`,
  // 佳智慧资讯bannerList
  dynamicSwiper: `/_api//v1/banner/${dynamicBannerList}`,
  // 服务支持bannerList
  serveSwiper: `/_api//v1/banner/${serveBannerList}`,
  // 招商代理bannerList
  agentSwiper: `/_api//v1/banner/${agentBannerList}`,
  // 关于我们bannerList
  aboutSwiper: `/_api//v1/banner/${aboutBannerList}`,
  advertising: `/_api//v1/banner/${advertising}`,
  advertising: `/_api//v1/banner/${advertising}`,
  planTop: `/_api//v1/banner/${planTop}/list`,
  information: `/_api//v1/banner/${information}/list`,
  editor: `/_api//v1/articles/page`,
  dynamicDetail: `/_api//v1/articles/`,
  partners: `/_api//v1/banner/${partners}`,
  layout: `/_api//v1/banner/`,
  inbanner: `/_api//v1/banner/${inbanner}/list`,
  introduction: `/_api//v1/banner/${introduction}/list`,
  qualification: `/_api//v1/banner/${qualification}`,
  base: `/_api//v1/banner/${base}`,
  contact: `/_api//v1/banner/${contact}`,
  cases: `/_api//v1/banner/${cases}/list`,
  casesImg: `/_api//v1/banner/${casesImg}/list`,
  fireTop: `/_api//v1/banner/${fireTop}`,
  fireForm: `/_api//v1/banner/${fireForm}`,
  FireProblem: `/_api//v1/banner/${FireProblem}`,
  technology: `/_api//v1/banner/${technology}`,
  solutions: `/_api//v1/banner/${solutions}/list`,
  trait: `/_api//v1/banner/${trait}`,
  productTop: `/_api//v1/banner/${productTop}`,
  productTop1: `/_api//v1/banner/${productTop1}`,
  productTop2: `/_api//v1/banner/${productTop2}`,
  productTop3: `/_api//v1/banner/${productTop3}`,
  productTop4: `/_api//v1/banner/${productTop4}`,
  productTop5: `/_api//v1/banner/${productTop5}`,
  productTop6: `/_api//v1/banner/${productTop6}`,
  productTop7: `/_api//v1/banner/${productTop7}`,
  productTop8: `/_api//v1/banner/${productTop8}`,
  productTop9: `/_api//v1/banner/${productTop9}`,

  classifyImg: `/_api//v1/category/page`,
  classifyImgh: `/_api//v1/banner/${classifyImgh}`,
  allProducts: `/_api//v1/spu/listHomePage`,
  fireProducts: `/_api//v1/spu/`,
  agedProducts: `/_api//v1/banner/${agedProducts}`,
  helmetProducts: `/_api//v1/banner/${helmetProducts}`,
  baseProducts: `/_api//v1/banner/${baseProducts}`,
  twoFireProducts: `/_api//v1/category/sub-page`,
  general: `/_api//v1/`,
  blogroll: `/_api//v1/banner/${blogroll}/list`,
  shoppingList: `/_api//v1/cart/getCartList`, //购物车列表
  addShopping: `/_api//v1/cart/add`, //添加购物车
  updateCartNum: `/_api//v1/cart/updateCartNum`, //修改购物车数量
  checkSome: `/_api//v1/cart/checkSome`, //购物车选中数量
  delCart: `/_api//v1/cart/delCartProduct`, //删除购物车
  createOrder: '/_api//v1/order',//创建订单
  address: '/_api//v1/address/create',//新增地址
  addresslist: '/_api//v1/address/list',//查询地址列表
  addresslist1: '/_api//v1/address/list1',
  delAddress: '/_api//v1/address/delAddress',//删除收货地址
  editAddress: '/_api//v1/address/update',//修改收货地址
  addAddress: '/_api//v1/address',//新增收货地址
  getOpenId: `/_api//cms/pay/yz`,
  orderPay: '/_api//admin/order/order-pay/',//订单支付详情
  orderList: '/_api//admin/order/orders/list',//我的订单列表
  offOrder: '/_api//v1/order/orders/cancel',//取消订单
  payOrder: '/_api//cms/pay/createMall',//选择支付方式
  prepayOrder: '/_api//cms/pay/createMall2',//获取预支付单
  queryPayState: '/_api//cms/pay/getMallStatus',//查询支付状态
  queryPayState1: '/_api//cms/pay/getMallStatus1',//查询支付状态
  queryPayState2: '/_api//cms/pay/yzurl',//查询支付状态
  getSkuOrderInfo: '/_api//admin/order/orders/getSkuOrderInfo',//立即购买
  getPostList: '/_api//v1/posts/getPostList',//论坛首页
  published: '/_api//v1/posts',//论坛文章发布
  inVideo: '/_api//cms/video/uploadVideo',//发表视频
  delPostInfo: '/_api//v1/posts/delPostInfo',//删除我的文章
  getPostById: '/_api//v1/posts/getPostById',//根据id查询文章详情
  addComment: '/_api//v1/posts/addComment',//评论当前文章
  getPostsByTarId: '/_api//v1/posts/getPostsByTarId',//当前文章所有的评论
  addChat: '/_api//v1/posts/addChat',//回复评论
  onlineVideo: '/_api//cms/video/getVideoData',//在线视频
  orderreason: '/_api//v1/order-reason/list',//根据分类查询取消订单或退货原因
  fileList: '/_api//v1/files/page',//资料列表查询
  fileDownload: '/_api//v1/files/downloadAttachment',//资料下载
  hotTopic: '/_api//v1/posts/getPopular',//热门话题
  forumTop: `/_api//v1/banner/${forumTop}/list`,

  detailEditor: `/_api//v1/spu/detailEditor`,
  transfer: '/_api//Logistics/transfer',
  leaveMassage: '/_api//v1/leave-massage',

  supplier: `/_api//v1/articles/${supplier}/detail`,//供应商留言
  agentserver: `/_api//v1/articles/${agentarticle}/detail`,  //招商代理
  customer: `/_api//v1/banner/${customer}`,//客户留言
  jump: `/_api//cms/user/setJump`,
  confirmGoods: '/_api//admin/order/orders/update',

  logisticSdynamics:`/_api//admin/order/logistics`, //物流动态
  InternationalPayments:'/_api//goAll/pay/createCashier', //国际支付
  aftersaleService:'/_api//v1/salesRecords/add', //售后服务
  privacyClause:'/_api//v1/banner/147', //隐私条款
  returnAndRefundPolicy:'/_api//v1/banner/148', //退货退款政策
  userAgreement:'/_api//v1/banner/149', //用户协议

  test:'https://wlb.babazhn.com/auth/login' //测试
}
